<template>
  <div class="bg-primaryV2 flex justify-center w-6 px-1 py-[1px] rounded-4xl">
    <span class="text-surface-10 l3 text-center">{{ props.text }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});
</script>
